<template>
  <div>
    <v-alert
      v-for="(item, key) in toolsInfo"
      :key="key"
      :color="item.problematic"
      dark
      :icon="returnIcon(item.problematic)"
      border="left"
      prominent
    >
      {{ item.name }}
      <p v-html="item.description"></p>
      <a v-if="item.link" :href="item.link">Weitere Informationen</a>
    </v-alert>
    <h4 class="sectionSubheader">Cookies</h4>
    <div v-if="cookieInfo.length > 0">
      Die folgenden Cookies werden ohne Einwilligung des Users gesetzt.
      <v-simple-table class="v-data-table elevation-3 v-data-table--dense mb-5">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Domain</th>
              <th class="text-left">Name</th>
              <th class="text-left">Anbieter</th>
              <th class="text-left">Laufzeit</th>
              <th class="text-left">Zweck</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, key) in cookieInfo" :key="key">
              <td>
                {{ item.domain }}
                <span v-show="checkThirdParty(item.domain)" class="red--text">
                  (3rd Party)</span
                >
              </td>
              <td>
                {{ truncateString(item.name) }}
              </td>
              <td>{{ item.platform }}</td>
              <td>{{ item.retention }}</td>
              <td>{{ checkCookieFunction(item.category) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <div v-else class="mb-3 mt-3">
      <v-list-item>
        <v-list-item-icon>
          <v-icon x-large color="green darken-2">mdi-thumb-up</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          Gute Arbeit: Ihre Seite setzt bei pageload keine Cookies. Es ist aber
          möglich, dass bei User-Interaktion Cookies gesetzt werden. Eine
          manuelle Prüfung kann sinnvoll sein.
        </v-list-item-content>
      </v-list-item>
    </div>
    <InfoBox :infoContent="infoContent.cookieInfo" />
    <h4 class="sectionSubheader">3rd Party Requests</h4>

    <div v-if="domainInfo.length > 0">
      <div>Von folgenden Domains werden Daten abgerufen.</div>
      <v-simple-table class="v-data-table elevation-3 v-data-table--dense mb-5">
        <template v-slot:default>
          <thead>
            <tr>
              <th></th>
              <th class="text-left">Domain</th>
              <th class="text-left">Info</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in domainInfo" :key="item.key">
              <td><IsDanger :rating="item.problematic" /></td>
              <td>{{ item.domain }}</td>
              <td>
                {{ item.description }}
                <span v-show="item.problematic == 'alert'" class="red--text">
                  Zustimmungspflichtig!</span
                >
                <span
                  v-show="item.problematic == 'success'"
                  class="green--text"
                >
                  WAHRSCHEINLICH(!) unbedenklich</span
                >
                <span
                  v-show="item.problematic == 'warning'"
                  class="orange--text"
                >
                  Einzelfall-Prüfung empfohlen.</span
                >
                {{ checkDanger(item.problematic) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div v-else class="mb-3 mt-3">
      <v-list-item>
        <v-list-item-icon>
          <v-icon x-large color="green darken-2">mdi-thumb-up</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          Gute Arbeit: Es wurden bei Pageload keine bedenklichen
          3rd-Party-Requests gefunden. Dies bedeutet nicht, dass nicht verzögert
          oder durch User-Interaktion 3rd-Party-Requests ausgeführt werden
          können.
        </v-list-item-content>
      </v-list-item>
    </div>
    <InfoBox :infoContent="infoContent.thirdPartyInfo" />
    <h4 class="sectionSubheader">localStorage</h4>
    <div v-if="Object.keys(localStorageData).length > 0">
      Die folgenden Daten werden im localStorage gespeichert.
      <LsTable :tableData="localStorageData" />
    </div>
    <div v-else class="mb-3 mt-3">
      <v-list-item>
        <v-list-item-icon>
          <v-icon x-large color="green darken-2">mdi-thumb-up</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          Gute Arbeit: Ihre Seite speichert keine Daten im locaStorage des
          Browsers
        </v-list-item-content>
      </v-list-item>
    </div>
    <InfoBox :infoContent="infoContent.localStorageInfo" />
  </div>
</template>
<script>
import axios from "axios";
import IsDanger from "./DangerElement";
import InfoBox from "./InfoboxElement";
import LsTable from "./localStorageElement";
export default {
  components: {
    IsDanger,
    InfoBox,
    LsTable,
  },
  props: {
    domainData: Array,
    cookieData: Array,
    localStorageData: Object,
    domain: String,
    infoContent: Object,
  },
  data() {
    return {
      domainInfo: {},
      cookieInfo: {},
      toolsInfo: {},
      localStorage: {
        name: "Nutzung des localStorage",
        description:
          "Der localStorage ist Cookies rechtlich gleichgestellt, d.h. die gespeicherten Daten müssen essentiell für die Funktion der Webseite sein. Prüfung sinnvoll",
        problematic: "warning",
        link: "https://www.etracker.com/cookie-urteil-des-eugh-auswirkungen-auf-den-einsatz-von-etracker/",
      },
      marketingCookie: {
        name: "Marketing- oder Analyse-Cookie gefunden",
        description:
          "Marketing- oder Analyse-Cookie gefunden, diese dürfen nur nach Zustimmung gesetzt werden. Eindeutiger TTDSG-Verstoß",
        problematic: "alert",
        link: "https://usercentrics.com/de/knowledge-hub/bgh-urteil-marketing-cookies-nur-mit-einwilligung/",
        set: false,
      },
      functionalCookie: {
        name: "Unbekannter oder Funktionaler Cookie gefunden",
        description:
          "Einen oder mehrere Cookies mit unbekanntem oder funktionalem Zweck gefunden.<br>Prüfung sinnvoll",
        problematic: "warning",
        link: false,
        set: false,
      },
      thirdPartyCookie: {
        name: "3rd-Party Cookie gefunden",
        description:
          "Einen oder mehrere 3rd-Party Cookies gefunden. Diese sind in aller Regel zustimmungspflichtig",
        problematic: "alert",
        link: "https://www.datenschutz.org/third-party-cookies/#third-party-cookies-was-gilt-laut-dsgvo",
        set: false,
      },
    };
  },
  created() {
    const domains = this.domainData;
    const cookies = this.cookieData;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ domains, cookies }),
    };

    axios
      .post(
        "https://tools.lawinenstift.de/ranking/datenschutz.php",
        requestOptions
      )
      .then((response) => {
        this.domainInfo = response.data.domains;
        this.cookieInfo = response.data.cookies;
        this.toolsInfo = response.data.tools;
        if (Object.keys(this.localStorageData).length > 0) {
          this.toolsInfo.push(this.localStorage);
          this.$emit("setWarning");
        }
      });
  },
  methods: {
    checkDanger(string) {
      if (string == "alert") this.$emit("setAlert");
      if (string == "warning") this.$emit("setWarning");
    },
    checkCookieFunction(string) {
      if (string == "Marketing" || string == "Analytics") {
        if (this.marketingCookie.set === false) {
          this.toolsInfo.push(this.marketingCookie);
          this.marketingCookie.set = true;
        }
        this.$emit("setAlert");
      }
      if (string == "Unbekannt" || string == "Functional") {
        if (this.functionalCookie.set === false) {
          this.toolsInfo.push(this.functionalCookie);
          this.functionalCookie.set = true;
        }
        this.$emit("setWarning");
      }
      return string;
    },
    returnIcon(string) {
      if (string == "alert") return "mdi-alert";
      if (string == "warning") return "mdi-help-circle";
      if (string == "success") return "mdi-checkbox-marked-circle";
    },
    truncateString(str) {
      let num = 25;
      if (str.length > num) {
        return str.slice(0, num - 3) + "...";
      } else {
        return str;
      }
    },
    checkThirdParty(string) {
      if (string.indexOf(this.domain) == -1) {
        if (this.domain.indexOf(string) == -1) {
          this.$emit("setAlert");
          if (this.thirdPartyCookie.set === false) {
          this.toolsInfo.push(this.thirdPartyCookie);
          this.thirdPartyCookie.set = true;
          }
          return true;
        }
      } else return false;
    },
  },
};
</script>
<style>
.v-alert a {
  color: #ffffff !important;
}
.v-alert p {
  margin-bottom: 0;
}
</style>
<template>
  <v-footer padless color="primary">
    <v-col class="text-center white--text" cols="12">
      {{ new Date().getFullYear() }} — <MyLogo color="#fff" class="flogo" />
    </v-col>
  </v-footer>
</template>

<script>
import MyLogo from "./LogoElement";
export default {
  components: {
    MyLogo,
  },
};
</script>
<style scoped>
.flogo {
  height: 1.4em;
  display: inline-flex;
  align-self: center;
  top: 0.125em;
  position: relative;
}
</style>
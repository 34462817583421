<template>
  <section id="report">
    <v-container>
      <v-row>
        <v-col cols="12" md="4" xs="4">
          <MyLogo color="#666" />
        </v-col>
        <v-col cols="12" md="8">
          <h1>Webseiten-Report</h1>
          für
          <a :href="finalUrl"
            ><strong>{{ finalUrl }}</strong></a
          >
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="loaded">
      <v-row>
        <v-col cols="12" md="2" class="text-center">
          <v-icon
            v-if="alertSet"
            :size="130"
            color="red darken-2"
            class="ico-lg"
          >
            mdi-alert
          </v-icon>
          <v-icon
            v-else-if="warningSet"
            :size="130"
            color="yellow darken-2"
            class="ico-lg"
          >
            mdi-help-circle-outline
          </v-icon>
          <v-icon v-else :size="130" color="green darken-2" class="ico-lg">
            mdi-thumb-up
          </v-icon>
          <h2>Datenschutz</h2>
        </v-col>
        <v-col cols="6" md="2" class="text-center">
          <v-progress-circular
            :rotate="-90"
            :size="120"
            :width="12"
            :value="scoreSicherheit"
            :color="scoreColor(scoreSicherheit)"
          >
            {{ scoreSicherheit }}<span class="von100">/100</span>
          </v-progress-circular>
          <h2>Sicherheit</h2>
        </v-col>
        <v-col cols="6" md="2" class="text-center">
          <v-progress-circular
            v-if="scoreLeistung"
            :rotate="-90"
            :size="120"
            :width="12"
            :value="scoreLeistung"
            :color="scoreColor(scoreLeistung)"
          >
            {{ Math.round(scoreLeistung) }}<span class="von100">/100</span>
          </v-progress-circular>
          <v-icon v-else :size="130" color="red darken-2" class="ico-lg">
            mdi-help-circle-outline
          </v-icon>
          <h2>Leistung</h2>
        </v-col>

        <v-col cols="12" md="6">
          <v-row no-gutters>
            <v-col cols="4" class="align-center">
              <v-img
                contain
                class="mx-auto"
                max-height="160"
                max-width="175"
                :src="lhdata.screenshotMobile"
              ></v-img>
            </v-col>
            <v-col cols="8" class="align-center">
              <v-img
                contain
                class="mx-auto"
                max-height="160"
                max-width="350"
                :src="lhdata.screenshotDesktop"
              ></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-16">
        <v-col cols="12" md="8">
          <h3 class="sectionTitle">Ihre Ergebnisse: Datenschutz</h3>

          <h4 class="sectionSubheader">Analyse</h4>
          <v-alert
            v-if="!alertSet && !warningSet"
            color="success"
            dark
            icon="mdi-checkbox-marked-circle"
            border="left"
            prominent
          >
            Das sieht gut aus
            <p>
              Wir haben auf dieser Seite keine datenschutzrechtlich bedenklichen
              Elemente gefunden.
            </p>
            <p>
              Es wurde allerdings nur Ihre Startseite gescanned, häufig befinden
              sich derartige Elemente auf Unterseiten, z.B. google Maps auf
              einer Anfahrt- oder ReCaptcha auf der Kontakt-Seite
            </p>
          </v-alert>
          <v-alert
            v-if="alertSet"
            color="alert"
            dark
            icon="mdi-alert"
            border="left"
            prominent
          >
            Datenschutz-Verstöße festgestellt
            <p>
              Es wurden ein oder mehrere Elemente gefunden, die nach unserem
              Kenntnisstand eindeutig gegen aktuelle Datenschutzbestimmungen
              verstoßen.
            </p>
          </v-alert>
          <v-alert
            v-if="warningSet"
            color="warning"
            dark
            icon="mdi-help-circle"
            border="left"
            prominent
          >
            Manuelle Prüfung empfohlen
            <p>
              Es wurden Elemente gefunden, die entweder unbekannt sind, oder bei
              denen eine Zustimmungspflicht nach unserem derzeitigen
              Kenntnisstand strittig oder von der Konfiguration des jeweiligen
              Tools abhängig ist.
            </p>
          </v-alert>

          <InfoDatenschutz
            :cookieData="lhdata.cookies"
            :domainData="lhdata.requestDomains"
            :localStorageData="lhdata.localStorage"
            :infoContent="infoboxen"
            :domain="lhdata.requestDomains[0]"
            @setAlert="setAlert"
            @setWarning="setWarning"
          />
        </v-col>
        <v-col cols="12" md="4" class="pt-16">
          <v-card elevation="2" outlined shaped>
            <v-card-title>Der Datenschutz Check</v-card-title>
            <v-card-text>
              <h4>Was bringt der Test?</h4>
              <p class="text-justify">
                Dieser Test findet Verstöße gegen die DSGVO (bzw.das TTDSG). Der
                Test lädt die Seite und findet Cookies, 3rd-Party-Tools und
                Elemente die im localStorage des Besuchers gespeichert werden.
              </p>
              <h4>Wieso ist das wichtig?</h4>
              <p class="text-justify">
                Die Schonfrist ist vorbei. 2018 hatte man eine Flut von
                Abmahnungen erwartet. Dies ist so nicht eingetreten. Allerdings
                häufen sich die Urteile in der letzten Zeit und die Summe der
                Bußgelder steigt rapide
                <a
                  target="_blank"
                  href="https://www.enforcementtracker.com/?insights"
                  >(Stand März 2022: 1,6Mrd)</a
                >.<br />
                Die Rechtsprechung scheint auch zunehmend strenger zu werden.
                Beispiele:<br />

                <a
                  href="https://noyb.eu/de/oesterr-dsb-eu-us-datenuebermittlung-google-analytics-illegal"
                  >Google Analytics</a
                ><br />
                <a
                  href="https://www.taylorwessing.com/de/insights-and-events/insights/2021/12/vg-wiesbaden-prohibits-use-of-content-delivery-networks"
                  >CDN Nutzung illegal</a
                >
                <br />
                <a
                  href="https://www.ra-plutte.de/lg-muenchen-dynamische-einbindung-google-web-fonts-ist-dsgvo/#google-fonts-lg-muenchen"
                  >Google Fonts Schadensersatz</a
                >
                <br />
                <a
                  href="https://dr-dsgvo.de/google-tag-manager-einwilligungspflicht-wegen-datenverarbeitung-in-den-usa-durch-google/"
                  >Google Tag Manager</a
                >
              </p>
              <h4>Was muss ich beachten?</h4>
              <p class="text-justify">
                Es gibt Techniken wie lazy-loading, verzögerte
                Script-Ausführung, etc. die das Aufspüren von Cookies und
                3rd-Party-Tools teilweise verhindern können. Um sicher zu sein,
                dass die eigene Webseite TTDSG-Konform ist, bedarf es manueller
                Tests über alle Seiten der Webpräsenz. Wir übernehmen das gerne
                für Sie, kontaktieren Sie uns:<br />
                <v-btn
                  color="primary"
                  href="https://www.lawinenstift.com/kontakt/"
                  depressed
                  elevation="2"
                  >Kontakt</v-btn
                >
              </p>
            </v-card-text>
          </v-card>
          <QuickCheckOffer class="mt-6" />
          <WordpressRenovationOffer
            class="mt-6"
            :wIcon="wordpressIcon"
            v-if="
              scoreLeistung < 50 &&
              scoreSicherheit < 50 &&
              alertSet &&
              wordpress
            "
          />
        </v-col>
      </v-row>

      <v-row class="mt-16">
        <v-col cols="12" md="8">
          <h3 class="sectionTitle">Ihre Ergebnisse: Sicherheit</h3>
          <h4 class="sectionSubheader mb-3 mt-3">SSL-Zertifikat</h4>
          <div v-if="lhdata.sslInfo._validTo">
            <v-list-item>
              <v-list-item-icon>
                <v-icon x-large color="green darken-2">mdi-thumb-up</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                Gute Arbeit: Ihre Seite verwendet eine SSL-Verbindung. Details:
              </v-list-item-content>
            </v-list-item>
            <InfoSsl :tableData="lhdata.sslInfo" />
          </div>
          <div v-else>
            <v-icon color="red darken-2" class="ico-lg">
              mdi-thumb-down
            </v-icon>
            Dringender Handlungsbedarf: Ihre Seite verwendet keine
            SSL-Verbindung.
          </div>
          <InfoBox :infoContent="infoboxen.sslInfo" />
          <h4 class="sectionSubheader mb-3 mt-3">
            Javascript mit Sicherheitslücken
          </h4>
          <div v-if="lhdata.security['no-vulnerable-libraries']">
            <JsTable
              class="mb-3 mt-3"
              v-if="lhdata.security['no-vulnerable-libraries'].score != 1"
              :tableData="
                lhdata.security['no-vulnerable-libraries'].details.items
              "
            />
            <div v-else>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon x-large color="green darken-2">mdi-thumb-up</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  Gute Arbeit: Keine Javascript-Bibliotheken mit
                  Sicherheitslücken gefunden.
                </v-list-item-content>
              </v-list-item>
            </div>
          </div>
          <div v-else>
            <v-list-item>
              <v-list-item-icon>
                <v-icon x-large color="red darken-2">mdi-help-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                Prüfung konnte nicht durchgeführt werden (google
                lighthouse-error)
              </v-list-item-content>
            </v-list-item>
          </div>
          <InfoBox :infoContent="infoboxen.javascriptInfo" />
          <h4 class="sectionSubheader mb-3 mt-3">Server-Header</h4>
          <ServerHeader :tableData="lhdata.securityDetails.header" />
          <InfoBox :infoContent="infoboxen.serverHeaderInfo" />
        </v-col>
        <v-col cols="12" md="4" class="pt-16">
          <v-card elevation="2" outlined shaped>
            <v-card-title>Der Sicherheits-Check</v-card-title>
            <v-card-text>
              <h4>Was wird getestet?</h4>
              <p class="text-justify">
                Wir testen ob SSL genutzt wird, welche Sicherheits-einstellungen
                Ihr Webspace nutzt und ob Javascript-Bibliotheken mit bekannten
                Sicherheitslücken eingesetzt werden.
              </p>
              <h4>Wieso ist das wichtig?</h4>
              <p class="text-justify">
                Als Webseitenbetreiber haftet man für Schäden, die beim Besucher
                entstehen. Dafür muss die Webseite oder der Server gar nicht
                gehackt werden. Es reicht, wenn es einem Angreifer zum Beispiel
                durch ein Formular gelingt, bösartiges Javascript in die Seite
                einzufügen. Dagegen kann man sich teilweise durch Server-Header
                absichern. Wir helfen Ihnen gerne dabei.
                <br />
                <v-btn
                  color="primary"
                  href="https://www.lawinenstift.com/kontakt/"
                  depressed
                  elevation="2"
                  >Kontakt</v-btn
                >
              </p>
              <h4>Was muss ich beachten?</h4>
              <p class="text-justify">
                Dieses Tool testet nicht die Sicherheit Ihres Servers gegen
                Hacker-Angriffe, sondern die potentielle Gefahr, die von Ihrer
                Webseite für den Besucher ausgeht.
              </p>
            </v-card-text>
          </v-card>
          <SecurityOffer class="mt-6" v-if="scoreSicherheit < 60" />
        </v-col>
      </v-row>

      <v-row class="mt-16">
        <v-col cols="12" md="8">
          <h3 class="sectionTitle">
            Ihre Ergebnisse: Leistung / Geschwindigkeit
          </h3>
          <h4 class="sectionSubheader">Lighthouse Web-Vitals</h4>
          <div v-if="lhdata.analysisUTCTimestamp">
            <div v-if="finalUrl">
              <v-row>
                <v-col
                  v-for="(value, name) in lhdata.vitals"
                  :key="name"
                  cols="6"
                  md="4"
                >
                  {{ value.title }} <br />
                  <span class="vitalscore"> {{ value.displayValue }} </span
                  ><br />
                  <MyRating :score="value.score * 100" />
                  {{ Math.round(value.score * 100) }}/100
                </v-col>
              </v-row>
            </div>
          </div>
          <div v-else>
            <v-list-item>
              <v-list-item-icon>
                <v-icon x-large color="red darken-2">mdi-help-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                Prüfung konnte nicht durchgeführt werden (google
                lighthouse-error)
              </v-list-item-content>
            </v-list-item>
          </div>
          <InfoBox :infoContent="infoboxen.vitalsInfo" />
          <h4 class="sectionSubheader mt-5">Google Feld-Daten</h4>
          <div v-if="lhdata.analysisUTCTimestamp">
            <v-row v-if="lhdata.labdata.metrics">
              <v-col
                v-for="(value, name) in lhdata.labdata.metrics"
                :key="name"
                cols="6"
              >
                {{ fielddata[name] }} <br />
                <MyRating :rating="value.category" />
              </v-col>
            </v-row>
            <div v-else>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon x-large color="red darken-2">mdi-emoticon-sad</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  Leider hat diese Seite nicht ausreichend Besucher um
                  Feld-Daten zu generieren.
                </v-list-item-content>
              </v-list-item>
            </div>
          </div>
          <div v-else>
            <v-list-item>
              <v-list-item-icon>
                <v-icon x-large color="red darken-2">mdi-help-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                Prüfung konnte nicht durchgeführt werden (google
                lighthouse-error)
              </v-list-item-content>
            </v-list-item>
          </div>
          <InfoBox :infoContent="infoboxen.fieldInfo" />
          <h4 class="sectionSubheader mt-5">Sonstige Informationen</h4>
          <div v-if="lhdata.analysisUTCTimestamp">
            <v-list-item v-if="lhdata.info['total-byte-weight']">
              <v-list-item-icon>
                <MyRating
                  :score="lhdata.info['total-byte-weight'].score * 100"
                />
              </v-list-item-icon>
              <v-list-item-content>
                {{ lhdata.info["total-byte-weight"].displayValue }}
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="
                lhdata.security['errors-in-console'] &&
                lhdata.security['errors-in-console'].score != 1
              "
            >
              <v-list-item-icon>
                <MyRating :score="0" />
              </v-list-item-icon>
              <v-list-item-content>
                Fehler in der Konsole:
                {{ lhdata.security["errors-in-console"].details.items.length }}
                <span
                  v-html="
                    parseMarkdown(
                      lhdata.security['errors-in-console'].description
                    )
                  "
                ></span>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-for="item in lhdata.info.techstack.systems"
              :key="item.id"
            >
              <v-list-item-icon>
                <img :src="item.iconDataURL" width="40" height="40" />
              </v-list-item-icon>
              <v-list-item-content> {{ item.title }} </v-list-item-content>
              {{ isWordpress(item) }}
            </v-list-item>

            <div
              class="mb-1 mt-2"
              v-if="
                lhdata.info.techstack['js-libraries'] &&
                lhdata.info.techstack['js-libraries'].details
              "
            >
              Eingesetzte Javascript-Frameworks/-Bibliotheken:

              <TechStack
                :tableData="lhdata.info.techstack['js-libraries'].details.items"
              />
            </div>
          </div>
          <div v-else>
            <v-list-item>
              <v-list-item-icon>
                <v-icon x-large color="red darken-2">mdi-help-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                Prüfung konnte nicht durchgeführt werden (google
                lighthouse-error)
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-col>
        <v-col cols="12" md="4" class="pt-16">
          <v-card elevation="2" outlined shaped>
            <v-card-title>Der Leistungs-Check</v-card-title>
            <v-card-text>
              <h4>Was wird getestet?</h4>
              <p class="text-justify">
                Es werden die Core-Web-Vitals getestet, und falls vorhanden, die
                Google Feld-Daten
              </p>
              <h4>Wieso ist das wichtig?</h4>
              <p class="text-justify">
                Eine langsame Seite kann die Besucher vergraulen. Dies führt zu
                eine hohen Absprungrate, was wiederum zu einer Abwertung bei
                google führen kann. Aber an dieser Stelle wollen wir mal mit
                einem immer wieder kolportierten google Mythos aufräumen: "eine
                langsame Webseite wird von google abgestraft". <br />
                Ja,
                <a
                  href="https://developer.chrome.com/blog/search-ads-speed/"
                  target="_blank"
                  >Speed ist ein (positiver) Ranking-Faktor</a
                >, aber auch nur, wenn dieser messbar ist.<br /><a
                  href="https://twitter.com/JohnMu/status/1369254821551874049"
                  target="_blank"
                  >Labor-Daten (lighthouse) sind kein Ranking-Faktor</a
                >.<br />
                Weitere Informationen
                <a
                  href="https://www.erwinhofman.com/core-web-vitals-faq/data/#will-i-still"
                  >finden Sie hier</a
                >
              </p>
              <h4>Was muss ich beachten?</h4>
              <p class="text-justify">
                Wir nutzen googles lighthouse zum erstellen des Reports,
                bewerten die Ergebnisse aber anders als lighthouse.
              </p>
            </v-card-text>
          </v-card>
          <WordpressBoostOffer
            class="mt-6"
            :wIcon="wordpressIcon"
            v-if="scoreLeistung < 50 && wordpress"            
          />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";
import MyLogo from "./LogoElement";
import MyRating from "./RatingElement";
import JsTable from "./TableElement";
import InfoBox from "./InfoboxElement";
import InfoDatenschutz from "./DatenschutzComponent";
import ServerHeader from "./ServerHeaderElement";
import InfoSsl from "./SslElement";
import TechStack from "./TechStackElement";
// import QuickCheckOffer from "./QuickCheckOffer";
// import WordpressRenovationOffer from "./WordpressRenovationOffer";
// import SecurityOffer from "./SecurityOffer";
// import WordpressBoostOffer from "./WordpressBoostOffer";

export default {
  name: "ReportComponent",
  components: {
    MyLogo,
    JsTable,
    MyRating,
    InfoDatenschutz,
    ServerHeader,
    InfoBox,
    InfoSsl,
    TechStack,
  //  QuickCheckOffer,
  //  WordpressRenovationOffer,
  //  SecurityOffer,
  //  WordpressBoostOffer,
  },
  data() {
    return {
      uuid: "",
      scoreLeistung: 75,
      infoboxen: {
        cookieInfo: {
          header: "Über Cookies",
          text: 'Cookies sind gar nicht so schlecht, wie ihr Ruf. Sie können z.B. den Login-Status oder Einstellungen speichern. Sie können allerdings auch für User-tracking und Marketing verwendet werden. Viele Tracking-Cookies lassen sich eindeutig identifizieren, aber häufig muss im Einzelfall geprüft werden, ob ein Cookie "gut" oder "böse" ist.',
        },
        thirdPartyInfo: {
          header: "3rd Party Requests",
          text: "Das sind alle Requests, die nicht der eigenen Domain zugeordnet werden. Das können CDN-Abrufe sein, eingebundene Youtube-Videos, Google-Maps, Externe Schriften oder Tracking-Scripts/-Pixel sein. Einige Aufrufe lassen sich eindeutig als unbedenklich oder zustimmungspflichtig klassifizieren, einige sind von uns noch nicht erfasst und bei anderen ist eine manuelle Überprüfung notwendig.",
        },
        localStorageInfo: {
          header: "Über den localStorage",
          text: "Der localStorage arbeitet sehr ähnlich wie Cookies, dies ist nur eine andere Möglichkeit Daten auf einem Endgerät zu speichern. Im Vergleich zu Cookies kann der localStorage deutlich mehr Daten speichern, diese können aber nicht über Domain-Grenzen hinweg abgerufen und manipuliert werden. Wenn diese Daten nicht für den Betrieb der Webseite technisch notwendig sind, sind sie genau so zustimmungspflichtig, wie Cookies oder 3rd-Party-Tools.",
        },
        sslInfo: {
          header: "SSL Verschlüsselung",
          text: 'Der Betrieb einer unverschlüsselten Webseite ist heute kaum noch zu rechtfertigen. Viele Provider bieten kostenlose SSL-Zertifikate von <a href="https://letsencrypt.org/">let\'s encrypt</a> an. Tut Ihr Provider das nicht, dann ist ihm sein Profit wichtiger, als die Sicherheit seiner Kunden. Sie sollten dann vielleicht über einen Provider-Wechsel nachdenken.',
        },
        javascriptInfo: {
          header: "Javascript-Bibliotheken",
          text: "Eine JavaScript-Bibliothek, die keine Sicherheits-Patches enthält, kann Ihre Website anfällig für verschiedene Angriffe machen. Sicherheitslücken in JavaScript-Bibliotheken können für Cross-Site-Scripting, Cross-Site-Request-Forgery und Buffer Overflow ausgenutzt werden.",
        },
        serverHeaderInfo: {
          header: "Server Header",
          text: "Die Implementierung von HTTP-Sicherheits-Headern ist ein relativ einfacher Weg, Ihre Webanwendungen abzusichern. Moderne Browser unterstützen viele HTTP-Header, die die Sicherheit von Webanwendungen verbessern können, um sie vor Clickjacking, Cross-Site-Scripting und anderen häufigen Angriffen zu schützen. Eine gute CSP kann darüber hinaus auch helfen, Datenschutzlücken zu schließen.",
        },
        fieldInfo: {
          header: "Google Felddaten",
          text: "Die Felddaten sind ein Bericht über die tatsächlich gemessene Geschwindigkeit und stellen anonymisierte Leistungsdaten von Benutzern in der realen Welt auf einer Vielzahl von Geräten und Netzwerkbedingungen dar. Im Gegensatz zu den lighthouse-Daten können diese ein positiver Ranking-Faktor sein. <br /><strong>First Input Delay (FID)</strong> misst die Interaktivität. Für ein gutes Nutzererlebnis sollten Seiten eine FID von 100 Millisekunden oder weniger haben. Diese kann nur bei echten Usern gemessen werden.<br /><strong>Interaction to Next Paint (INP)</strong> ist eine experimentelle Feldmetrik zur Bewertung der Reaktionsfähigkeit. INP protokolliert die Latenz aller Interaktionen während des gesamten Lebenszyklus der Seite. <br /><strong>Time to First Byte (TTFB)</strong> ist eine grundlegende Metrik zur Messung der Verbindungsaufbauzeit und der Reaktionsfähigkeit des Webservers.",
        },
        vitalsInfo: {
          header: "lighthouse Messdaten",
          text: "<strong>First Contentful Paint (FCP)</strong> misst, wie lange der Browser braucht, um das erste Stück DOM-Inhalt zu rendern, nachdem ein Benutzer Ihre Seite aufgerufen hat.<br /><strong>Time to Interactive (TTI)</strong> misst die Zeit vom Beginn des Ladens der Seite bis zum Zeitpunkt an dem die Seite komplett geladen ist und auf Besucher Input reagiert <br /><strong>Speed Index (SI)</strong> misst, wie schnell der Inhalt während des Ladens der Seite visuell dargestellt wird.<br><strong>Total Blocking Time (TBT)</strong> misst die Gesamtzeit zwischen FCP und TTI, in der der Haupt-Thread blockiert war, und eine Reaktion auf Eingaben nicht möglich ist.<br /><strong>Largest Contentful Paint (LCP)</strong> misst die Ladeleistung. Um ein gutes  Nutzererlebnis zu bieten, sollte LCP innerhalb von 2,5 Sekunden nach dem ersten Laden der Seite auftreten.<br /><strong>Cumulative Layout Shift (CLS)</strong> misst die visuelle Stabilität. Um eine gute Benutzererfahrung zu bieten, sollten die Seiten einen CLS-Wert von 0,1 oder weniger aufweisen.",
        },
      },
      loaded: false,
      finalUrl: "kein Report gefunden zu dieser ID",
      scoreSicherheit: 40,
      scoreDatenschutz: "alert",
      alertSet: false,
      warningSet: false,
      wordpress: false,
      wordpressIcon: "",
      lhdata: {
        finalUrl: "Kein Report gefunden",
      },
      fielddata: {
        CUMULATIVE_LAYOUT_SHIFT_SCORE: "Cumulative Layout Shift",
        FIRST_CONTENTFUL_PAINT_MS: "First Contentful Paint",
        FIRST_INPUT_DELAY_MS: "First Input Delay",
        LARGEST_CONTENTFUL_PAINT_MS: "Largest Contentful Paint",
        EXPERIMENTAL_INTERACTION_TO_NEXT_PAINT: "Interaction to Next Paint",
        EXPERIMENTAL_TIME_TO_FIRST_BYTE: "Time to First Byte",
      },
    };
  },
  created() {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("uuid")) {
      this.uuid = urlParams.get("uuid");
      axios
        .get(
          "https://tools.lawinenstift.de/ranking/quickcheck.php?UUID=" +
            this.uuid
        )
        .then((response) => {
          this.lhdata = response.data;
          if (this.lhdata != "Fehler: UUID existiert nicht (mehr)") {
            this.loaded = true;
            this.finalUrl = this.lhdata.finalUrl;
            this.scoreLeistung = this.lhdata.lighthousePerformanceScore;
            this.scoreSicherheit = this.lhdata.securityScore;
          }
        });
    }
  },
  methods: {
    scoreColor(num) {
      if (num < 40) {
        return "red";
      } else if (num < 70) {
        return "yellow darken-2";
      } else if (num <= 100) {
        return "green";
      } else return "grey";
    },
    setWarning() {
      this.warningSet = true;
    },
    isWordpress(data) {
      if (data.id == "wordpress") {
        this.wordpress = true;
        this.wordpressIcon = data.iconDataURL;
      }
    },
    setAlert() {
      this.alertSet = true;
    },
    parseMarkdown(markdownText) {
      const htmlText = markdownText.replace(
        /\[(.*?)\]\((.*?)\)/gim,
        "<a target='_blank' href='$2'>$1</a>"
      );
      return htmlText.trim();
    },
  },
};
</script>
<style>
.sectionTitle {
  font-size: 2rem;
}
.vitalscore {
  font-size: 2rem;
}
.sectionSubheader {
  font-size: 1.5rem;
}
.v-progress-circular {
  margin: 1rem;
  font-size: 2rem;
  font-weight: bold;
}
.ico-lg {
  margin: 10px;
}
.von100 {
  margin-top: 2rem;
  margin-left: -5px;
  font-size: 0.8rem;
  color: #333;
}
</style>
<template>
    <v-app>
      <ReportComponent /> 
      <FooterComponent /> 
    </v-app>    
</template>

<script>
import ReportComponent from './components/ReportComponent.vue'
import FooterComponent from './components/FooterComponent.vue'
export default {
  name: 'App',
  components: {
    ReportComponent,
    FooterComponent
  }
}
</script>

<style lang="scss">
@import '../node_modules/typeface-roboto/index.css';
#app {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}

</style>
